import { motion } from 'framer-motion';
import React from 'react';
import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';
import { ReactComponent as CityMarkerSvg } from '../../../assets/icons/circled-decorator.svg';
import { PresentationReference } from '../../../types';
import { fadeInFromBottomAnimation, SlideDirection, slideInHalfAnimation } from '../../animations';
import { AspectRatio, BaseSlideCss, SlideTitle } from '../../styled-components';
import { Logo } from '../../styled-components/logo';
import {
  contentColor,
  contrastTextColor,
  descriptionTextStyle,
  slideImageStyle,
  subSubTitleTextStyle,
  subTitleTextStyle,
  titleTextStyle,
} from '../../styles';
import { SlideVariation, Theme } from '../../types';
import Divider from '../shared/Divider/Divider';

type ReferenceSlideProps = {
  image: string;
  reference: PresentationReference;
  slideVariation: SlideVariation;
};

interface SlideProps {
  readonly slideVariation?: SlideVariation;
}

const Slide = styled.div<SlideProps>`
  ${BaseSlideCss};

  position: relative;

  flex-direction: ${(props) =>
    props.slideVariation === SlideVariation.ImageRight ? 'row-reverse' : 'row'};

  font-weight: bold;
`;

const Image = styled(motion.div)`
  ${slideImageStyle};

  width: 50%;
`;

const Info = styled(motion.div)<{ slideVariation: SlideVariation }>`
  color: ${contentColor};

  width: 50%;

  display: flex;
  flex-direction: column;

  margin-top: 5%;

  & > * {
    margin-left: ${(p) => (p.slideVariation === SlideVariation.ImageLeft ? '6.58%' : '14.58%')};

    &:first-child {
      margin-top: 5.1%;
    }
  }
`;

const Title = styled(motion.div)`
  ${titleTextStyle}
`;

const SubTitle = styled(motion.div)`
  ${subTitleTextStyle}

  margin-top: 1.5rem;
`;

const Type = styled(motion.div)<{ slideVariation: SlideVariation }>`
  ${subSubTitleTextStyle}

  margin-top: 1.37em;
  margin-left: ${(p) => (p.slideVariation === SlideVariation.ImageLeft ? '1.7em' : '3.65em')};
  letter-spacing: -0.01em;
`;

const FactsRow = styled(motion.div)<{ slideVariation: SlideVariation }>`
  display: flex;
  margin-top: 1.27em;
  margin-left: ${(p) => (p.slideVariation === SlideVariation.ImageLeft ? '2.4em' : '5.1em')};
  margin-bottom: 2.445em;
  line-height: 0.975em;
  font-size: 0.9em;
`;

const Fact = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1.4em;

  /* we have three items in a row = 1/3 per item */
  width: 33%;
  font-size: 1.1em;
  letter-spacing: -0.01em;
  line-height: 1.1em;

  & span {
    font-weight: normal;
    line-height: 3.4rem;
  }
`;

const Detail = styled(motion.div)<{ slideVariation: SlideVariation }>`
  ${(p) =>
    p.slideVariation === SlideVariation.ImageLeft
      ? css`
          font-size: 0.97em;
          line-height: 1.9em;
          margin-left: 2.4em;
        `
      : css`
          font-size: 0.97em;
          line-height: 1.4em;
          margin-left: 4.75em;
          margin-top: 0.55em;
        `}
`;

const DetailDiv = styled(motion.div)`
  margin: 0;
  padding: 0;
`;

const DividerWrapper = styled(motion.div)`
  margin: 0 -2em 0 -2em;
  width: 50%;
`;

const CityMarker = styled(CityMarkerSvg)`
  width: 4em;
`;

const CityName = styled.span`
  ${descriptionTextStyle};

  display: block;
  margin: 0 0.4em;
  color: ${contrastTextColor};
`;

const MarkerContainer = styled(motion.div)<SlideProps>`
  display: flex;
  align-items: center;
  flex-direction: row;

  transform-origin: 50% 50%;
  position: absolute;
  bottom: 3.9%;
  z-index: 20;

  ${({ slideVariation }) =>
    slideVariation === SlideVariation.ImageLeft
      ? css`
          left: 1.6%;

          ${CityMarker} {
            transform: rotate(180deg);
          }
        `
      : css`
          flex-direction: row-reverse;
          right: 1.3%;
        `};
`;

const animateChildrenAfterEachOther = { show: { transition: { staggerChildren: 0.75 } } };
const fadeInChildrenAnimation = {
  hidden: { opacity: 0, y: '5em' },
  show: { opacity: 1, y: 0, transition: { type: 'tween' } },
};

const ReferenceSlide: React.FC<ReferenceSlideProps> = ({ image, reference, slideVariation }) => {
  return (
    <AspectRatio>
      <Slide slideVariation={slideVariation}>
        <Image
          url={image}
          variants={
            slideVariation === SlideVariation.ImageLeft
              ? slideInHalfAnimation()
              : slideInHalfAnimation({ direction: SlideDirection.FromRight })
          }
          initial="hidden"
          animate="show"
        />

        {reference?.city && (
          <MarkerContainer slideVariation={slideVariation}>
            <CityMarker />
            <CityName>{reference?.city}</CityName>
          </MarkerContainer>
        )}

        <SlideTitle theme={Theme.Light}>Referenzen</SlideTitle>
        {slideVariation === SlideVariation.ImageRight && <SlideTitle>REFERENZEN</SlideTitle>}
        <Info
          slideVariation={slideVariation}
          variants={animateChildrenAfterEachOther}
          initial="hidden"
          animate="show"
        >
          <Title
            variants={
              slideVariation === SlideVariation.ImageLeft
                ? slideInHalfAnimation({ direction: SlideDirection.FromRight })
                : slideInHalfAnimation()
            }
            initial="hidden"
            animate="show"
          >
            {reference.name}
          </Title>
          <DividerWrapper>
            <Divider
              fromDirection={slideVariation === SlideVariation.ImageLeft ? 'right' : 'left'}
              delay={0.25}
            ></Divider>
          </DividerWrapper>
          <SubTitle
            variants={
              slideVariation === SlideVariation.ImageLeft
                ? slideInHalfAnimation({ direction: SlideDirection.FromRight })
                : slideInHalfAnimation()
            }
            initial="hidden"
            animate="show"
          >
            {reference.description}
          </SubTitle>
          <Type
            slideVariation={slideVariation}
            variants={fadeInFromBottomAnimation()}
            initial="hidden"
            animate="show"
          >
            {reference.type}
          </Type>
          <FactsRow slideVariation={slideVariation} variants={fadeInFromBottomAnimation()}>
            {reference.orderAmountInMillionEuros && (
              <Fact>
                AUFTRAGS-{/* due to current layout dimensions we need to hardcode line break: */}
                <br />
                SUMME
                <span>
                  <NumberFormat
                    value={reference.orderAmountInMillionEuros}
                    displayType={'text'}
                    suffix={' Mio. €'}
                  />
                </span>
              </Fact>
            )}
            {reference.grossFloorAreaInSquareMeters && (
              <Fact>
                BRUTTO-
                <br />
                GRUNDFLÄCHE
                <span>
                  <NumberFormat
                    value={reference.grossFloorAreaInSquareMeters}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    suffix={' m²'}
                  />
                </span>
              </Fact>
            )}
            {reference.grossSpaceCapacityInCubicMeters && (
              <Fact>
                BRUTTO-
                <br />
                RAUMINHALT
                <NumberFormat
                  value={reference.grossSpaceCapacityInCubicMeters}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  suffix={' m³'}
                />
              </Fact>
            )}
          </FactsRow>
          <DetailDiv variants={fadeInChildrenAnimation}>
            <Detail slideVariation={slideVariation}>BAUZEIT: {reference.constructionPeriod}</Detail>
            <Detail slideVariation={slideVariation}>
              AUSFÜHRENDE ABTEILUNG: {reference.executiveDepartment}
            </Detail>
            <Detail slideVariation={slideVariation}>BAUHERR: {reference.buildingOwner}</Detail>
            <Detail slideVariation={slideVariation}>ARCHITEKT: {reference.architect}</Detail>
          </DetailDiv>
        </Info>
        <Logo theme={slideVariation === SlideVariation.ImageLeft ? Theme.Dark : Theme.Light} />
      </Slide>
    </AspectRatio>
  );
};

export default ReferenceSlide;
